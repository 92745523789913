// src/components/FeaturesSection.js

import React, { useRef, useEffect } from 'react';
import { Truck, Shield, DollarSign, Users, Globe, Package } from 'lucide-react';

const FeaturesSection = () => {
  const scrollRef = useRef(null);

  useEffect(() => {
    const scrollContainer = scrollRef.current;
    if (!scrollContainer) return;

    const totalWidth = scrollContainer.scrollWidth;
    const visibleWidth = scrollContainer.offsetWidth;
    let scrollAmount = 0;
    const scrollStep = 0.4; // Adjust for slower/faster scrolling

    const scroll = () => {
      scrollAmount += scrollStep;
      if (scrollAmount >= totalWidth - visibleWidth) {
        scrollAmount = 0;
      }
      scrollContainer.scrollLeft = scrollAmount;
      requestAnimationFrame(scroll);
    };

    const animationFrame = requestAnimationFrame(scroll);

    return () => cancelAnimationFrame(animationFrame);
  }, []);

  const features = [
    { icon: Truck, title: "Efficient Deliveries", description: "Utilize existing trips for eco-friendly and cost-effective deliveries." },
    { icon: Shield, title: "Secure Packages", description: "Advanced tracking and insurance for peace of mind." },
    { icon: DollarSign, title: "Earn While You Travel", description: "Drivers can monetize their regular journeys." },
    { icon: Users, title: "Community-Driven", description: "Connect with local drivers and build a trusted network." },
    { icon: Globe, title: "Reduce Carbon Footprint", description: "Contribute to a greener planet by optimizing delivery routes." },
    { icon: Package, title: "Flexible Package Sizes", description: "From envelopes to large boxes, we've got you covered." },
  ];

  return (
    <section id="features" className="py-20 bg-gradient-to-r from-ffc0c6/20 to-white overflow-hidden">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl md:text-5xl font-bold text-center mb-16 text-gray-800">
          Here's Why You Should Piggybackit
        </h2>
        <div 
          ref={scrollRef}
          className="flex overflow-x-hidden pb-12"
        >
          {features.concat(features).map((feature, index) => (
            <FeatureCard key={index} {...feature} />
          ))}
        </div>
      </div>
    </section>
  );
};

const FeatureCard = ({ icon: Icon, title, description }) => (
  <div className="flex-shrink-0 w-80 h-96 bg-white rounded-2xl shadow-xl p-8 mr-8 flex flex-col items-center justify-center text-center">
    <Icon size={80} className="text-ffc0c6 mb-6" />
    <h3 className="text-2xl font-bold mb-4 text-gray-800">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

export default FeaturesSection;
