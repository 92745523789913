// src/components/TestimonialsSection.js

import React, { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft, ChevronRight, Quote, Star } from 'lucide-react';

const TestimonialsSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const testimonials = [
    {
      quote: "Piggyback has transformed my small business logistics. The cost savings and efficiency are unbeatable!",
      author: "Emily Chen",
      role: "Online Boutique Owner"
    },
    {
      quote: "As a frequent traveler, I now offset my trip costs by delivering packages. It's so simple and rewarding!",
      author: "Alex Thompson",
      role: "Digital Nomad"
    },
    {
      quote: "The environmental impact of Piggyback is what drew me in. It's great to reduce carbon emissions while helping others.",
      author: "Liam O'Connor",
      role: "Environmental Consultant"
    },
    {
      quote: "I was skeptical at first, but the security measures and insurance gave me peace of mind. Now I'm a regular user!",
      author: "Sophia Rodriguez",
      role: "Art Collector"
    },
    {
      quote: "The community aspect is fantastic. I've met so many interesting people while delivering packages on my commute.",
      author: "Jordan Lee",
      role: "Software Engineer"
    },
    {
      quote: "As a student, the extra income from deliveries has been a game-changer. It fits perfectly with my schedule.",
      author: "Zoe Parker",
      role: "University Student"
    }
  ];

  const nextTestimonial = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  }, [testimonials.length]);

  const prevTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
  };

  useEffect(() => {
    const timer = setInterval(nextTestimonial, 5000);
    return () => clearInterval(timer);
  }, [nextTestimonial]);

  return (
    <section id="testimonials" className="py-20 bg-gradient-to-b from-ffc0c6/10 to-white">
      <div className="container mx-auto px-4">
        <motion.h2 
          className="text-4xl md:text-5xl font-bold text-center mb-16 text-gray-800"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          What Our Community Says
        </motion.h2>
        <div className="relative">
          <AnimatePresence mode="wait">
            <motion.div
              key={currentIndex}
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -100 }}
              transition={{ duration: 0.5 }}
              className="bg-white p-8 rounded-xl shadow-xl max-w-3xl mx-auto"
            >
              <TestimonialCard testimonial={testimonials[currentIndex]} />
            </motion.div>
          </AnimatePresence>
          <button 
            onClick={prevTestimonial} 
            className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-ffc0c6 text-white p-2 rounded-full shadow-md hover:bg-ffc0c6/80 transition duration-300"
          >
            <ChevronLeft size={24} />
          </button>
          <button 
            onClick={nextTestimonial} 
            className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-ffc0c6 text-white p-2 rounded-full shadow-md hover:bg-ffc0c6/80 transition duration-300"
          >
            <ChevronRight size={24} />
          </button>
        </div>
      </div>
    </section>
  );
};

const TestimonialCard = ({ testimonial }) => {
  const starCount = Math.floor(Math.random() * 3) + 8; // 3.5 to 5 stars (7 to 10 half-stars)

  return (
    <div className="flex flex-col items-center">
      <Quote size={48} className="text-ffc0c6 mb-6" />
      <p className="text-xl md:text-2xl italic mb-6 text-center text-gray-700">"{testimonial.quote}"</p>
      <div className="flex justify-center mb-4">
        {[...Array(10)].map((_, i) => (
          <Star 
            key={i} 
            size={24} 
            className={i < starCount ? "text-yellow-400" : "text-gray-300"} 
            fill={i < starCount ? "currentColor" : "none"}
          />
        ))}
      </div>
      <p className="text-lg font-semibold text-gray-800">{testimonial.author}</p>
      <p className="text-md text-gray-600">{testimonial.role}</p>
    </div>
  );
};

export default TestimonialsSection;
