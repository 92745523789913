import React from 'react';

const TermsOfService = () => {
  return (
    <div className="bg-gradient-to-b from-[#FFC0C6] to-white min-h-screen pt-24 pb-16">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white shadow-2xl rounded-2xl overflow-hidden">
          <div className="px-8 py-12 sm:px-12 sm:py-16">
            <h1 className="text-5xl font-extrabold text-[#FF8A95] text-center mb-12">Terms of Service</h1>
            <div className="prose prose-lg max-w-none">
              <p className="text-2xl text-[#FF8A95] mb-10 text-center font-light">
                Welcome to Piggyback! These terms and conditions outline the rules and regulations for the use of Piggyback's Platform and Services.
              </p>
              
              <h2 className="text-3xl font-semibold text-[#FF8A95] mt-16 mb-6">Introduction</h2>
              <p className="text-gray-700">
                By accessing this website and using our services, we assume you accept these terms and conditions in full. Do not continue to use Piggyback's Platform if you do not accept all of the terms and conditions stated on this page.
              </p>
              
              <h2 className="text-3xl font-semibold text-[#FF8A95] mt-16 mb-6">License</h2>
              <p className="text-gray-700">
                Unless otherwise stated, Piggyback and/or its licensors own the intellectual property rights for all material on Piggyback. All intellectual property rights are reserved. You may view and/or print pages from https://www.piggybackit.com for your own personal use subject to restrictions set in these terms and conditions.
              </p>
              <p className="text-gray-700 mt-4 font-medium">
                You must not:
              </p>
              <ul className="list-disc pl-8 space-y-2 text-gray-700">
                <li>Republish material from https://www.piggybackit.com</li>
                <li>Sell, rent or sub-license material from https://www.piggybackit.com</li>
                <li>Reproduce, duplicate or copy material from https://www.piggybackit.com</li>
                <li>Redistribute content from Piggyback (unless content is specifically made for redistribution).</li>
              </ul>
              
              <h2 className="text-3xl font-semibold text-[#FF8A95] mt-16 mb-6">User Comments</h2>
              <p className="text-gray-700">
                This Agreement shall begin on the date hereof. Certain parts of this website offer the opportunity for users to post and exchange opinions, information, material, and data ('Comments') in areas of the website. Piggyback does not screen, edit, publish or review Comments prior to their appearance on the website and Comments do not reflect the views or opinions of Piggyback, its agents, or affiliates.
              </p>
              <p className="text-gray-700">
                Piggyback reserves the right to monitor all Comments and to remove any Comments which it considers in its absolute discretion to be inappropriate, offensive, or otherwise in breach of these Terms and Conditions.
              </p>
              
              <h2 className="text-3xl font-semibold text-[#FF8A95] mt-16 mb-6">Hyperlinking to our Content</h2>
              <p className="text-gray-700">
                The following organizations may link to our website without prior written approval:
              </p>
              <ul className="list-disc pl-8 space-y-2 text-gray-700">
                <li>Government agencies;</li>
                <li>Search engines;</li>
                <li>News organizations;</li>
                <li>Online directory distributors when they list us in the directory may link to our website in the same manner as they hyperlink to the websites of other listed businesses;</li>
                <li>Systemwide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.</li>
              </ul>
              
              <h2 className="text-3xl font-semibold text-[#FF8A95] mt-16 mb-6">Reservation of Rights</h2>
              <p className="text-gray-700">
                We reserve the right at any time and in its sole discretion to request that you remove all links or any particular link to our website. You agree to immediately remove all links to our website upon such request. We also reserve the right to amend these terms and conditions and its linking policy at any time. By continuing to link to our website, you agree to be bound to and abide by these linking terms and conditions.
              </p>
              
              <h2 className="text-3xl font-semibold text-[#FF8A95] mt-16 mb-6">Removal of Links from our Website</h2>
              <p className="text-gray-700">
                If you find any link on our website or any linked website objectionable for any reason, you may contact us about this. We will consider requests to remove links but will have no obligation to do so or to respond directly to you.
              </p>
              
              <h2 className="text-3xl font-semibold text-[#FF8A95] mt-16 mb-6">Disclaimer</h2>
              <p className="text-gray-700">
                To the maximum extent permitted by applicable law, we exclude all representations, warranties, and conditions relating to our website and the use of this website (including, without limitation, any warranties implied by law in respect of satisfactory quality, fitness for purpose, and/or the use of reasonable care and skill). Nothing in this disclaimer will:
              </p>

              <ul className="list-disc pl-8 space-y-2 text-gray-700">
                <li>Limit or exclude liability for death or personal injury caused by negligence or willful misconduct.</li>
                <li>Limit or exclude liability for fraud or fraudulent misrepresentation.</li>
                <li>Limit any liabilities in any way that is not permitted under applicable law.</li>
                <li>Exclude any liabilities that may not be excluded under applicable law.</li>
              </ul>
              
              <h2 className="text-3xl font-semibold text-[#FF8A95] mt-16 mb-6">Indemnification</h2>
              <p className="text-gray-700">
                You agree to indemnify, defend, and hold harmless Piggyback, its directors, officers, employees, agents, and affiliates, from and against any and all claims, damages, obligations, losses, liabilities, costs, or debt, and expenses (including but not limited to attorney's fees) arising from:
              </p>
              <ul className="list-disc pl-8 space-y-2 text-gray-700">
                <li>Your use and access of the Piggyback platform.</li>
                <li>Your violation of any term of these Terms of Service.</li>
                <li>Your violation of any third-party right, including without limitation any copyright, property, or privacy right.</li>
                <li>Any claim that your use of the Piggyback platform has caused damage to a third party.</li>
              </ul>

              <h2 className="text-3xl font-semibold text-[#FF8A95] mt-16 mb-6">Termination</h2>
              <p className="text-gray-700">
                We may terminate or suspend your access to our platform immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms of Service. All provisions of the Terms of Service which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.
              </p>
              
              <h2 className="text-3xl font-semibold text-[#FF8A95] mt-16 mb-6">Governing Law</h2>
              <p className="text-gray-700">
                These Terms shall be governed and construed in accordance with the laws of the state in which Piggyback is incorporated, without regard to its conflict of law provisions. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect.
              </p>
              
              <h2 className="text-3xl font-semibold text-[#FF8A95] mt-16 mb-6">Changes to These Terms</h2>
              <p className="text-gray-700">
                Piggyback reserves the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
              </p>
              <p className="text-gray-700">
                By continuing to access or use our platform after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the platform.
              </p>

              <h2 className="text-3xl font-semibold text-[#FF8A95] mt-16 mb-6">Contact Us</h2>
              <p className="text-gray-700">
                If you have any questions about these Terms, please contact us at <a href="mailto:support@piggybackit.com" className="text-[#FF8A95] hover:text-[#FF6B78] underline">support@piggybackit.com</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;