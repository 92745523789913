// src/components/HowItWorksSection.js

import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Package, Truck, MapPin, CheckCircle, UserPlus, CreditCard, ChevronRight, ChevronLeft } from 'lucide-react';

const HowItWorksSection = () => {
  const [activeSteps, setActiveSteps] = useState([]);
  const [activeSection, setActiveSection] = useState('sender');

  const steps = [
    { icon: Package, title: "List Your Item", description: "Describe your package and set your delivery preferences with our easy-to-use interface." },
    { icon: Truck, title: "Match with a Driver", description: "Our smart algorithm connects you with a reliable driver heading your way." },
    { icon: MapPin, title: "Real-Time Tracking", description: "Follow your package's journey in real-time with our advanced tracking system." },
    { icon: CheckCircle, title: "Secure Delivery", description: "Your item is safely delivered to its destination, with confirmation and feedback." },
  ];

  const driverSteps = [
    { icon: UserPlus, title: "Join Our Network", description: "Sign up as a driver and set your routes and availability." },
    { icon: Package, title: "Accept Deliveries", description: "Browse and accept delivery requests that match your journey." },
    { icon: Truck, title: "Pick Up & Deliver", description: "Collect the package and deliver it along your planned route." },
    { icon: CreditCard, title: "Get Paid Instantly", description: "Receive payment directly to your account upon successful delivery." },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll('.timeline-step');
      elements.forEach((el, index) => {
        if (isElementInViewport(el)) {
          setActiveSteps(prev => Array.from(new Set([...prev, index])));
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const isElementInViewport = (el) => {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  return (
    <section id="how-it-works" className="py-20 bg-gradient-to-b from-white to-ffc0c6/10">
      <div className="container mx-auto px-4">
        <motion.h2 
          className="text-4xl md:text-5xl font-bold text-center mb-16 text-gray-800"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          How Piggybackit Works
        </motion.h2>
        
        <div className="flex justify-center mb-10">
          <ToggleButton
            active={activeSection === 'sender'}
            onClick={() => setActiveSection('sender')}
          >
            Sending a Package
          </ToggleButton>
          <ToggleButton
            active={activeSection === 'driver'}
            onClick={() => setActiveSection('driver')}
          >
            Becoming a Driver
          </ToggleButton>
        </div>
        
        <AnimatePresence mode="wait">
          <motion.div
            key={activeSection}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
          >
            {activeSection === 'sender' ? (
              <Timeline steps={steps} activeSteps={activeSteps} />
            ) : (
              <Timeline steps={driverSteps} activeSteps={activeSteps} startIndex={5} />
            )}
          </motion.div>
        </AnimatePresence>
      </div>
    </section>
  );
};

const ToggleButton = ({ active, onClick, children }) => (
  <button
    className={`px-6 py-3 mx-2 rounded-full font-semibold transition-all duration-300 ${
      active ? 'bg-ffc0c6 text-white shadow-lg' : 'bg-white text-gray-600 hover:bg-gray-100'
    }`}
    onClick={onClick}
  >
    {children}
  </button>
);

const Timeline = ({ steps, activeSteps, startIndex = 1 }) => (
  <div className="relative">
    <div className="absolute left-1/2 transform -translate-x-1/2 h-full w-1 bg-ffc0c6"></div>
    {steps.map((step, index) => (
      <TimelineStep 
        key={index}
        step={step}
        index={index + startIndex}
        isActive={activeSteps.includes(index)}
        isLeft={index % 2 === 0}
      />
    ))}
  </div>
);

const TimelineStep = ({ step, index, isActive, isLeft }) => {
  const Icon = step.icon;
  const ChevronIcon = index % 2 === 0 ? ChevronLeft : ChevronRight;
  
  return (
    <motion.div 
      className={`flex items-center mb-12 ${isLeft ? 'flex-row-reverse' : ''} timeline-step`}
      initial={{ opacity: 0, x: isLeft ? -50 : 50 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
    >
      <div className={`w-1/2 ${isLeft ? 'pl-8' : 'pr-8'} ${isLeft ? 'text-right' : 'text-left'}`}>
        <motion.div 
          className={`bg-white p-6 rounded-lg shadow-lg transition-all duration-300 ${isActive ? 'scale-105 shadow-xl' : ''}`}
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center mb-3">
            <Icon className="text-ffc0c6 mr-2" size={24} />
            <h4 className="text-xl font-semibold">{step.title}</h4>
          </div>
          <AnimatePresence>
            {isActive && (
              <motion.p 
                className="text-gray-600"
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
              >
                {step.description}
              </motion.p>
            )}
          </AnimatePresence>
        </motion.div>
      </div>
      <div className="w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 flex items-center justify-center">
        <div className="bg-ffc0c6 w-8 h-8 rounded-full text-white flex items-center justify-center">
          <ChevronIcon size={20} />
        </div>
      </div>
    </motion.div>
  );
};

export default HowItWorksSection;