import React from 'react';
import { motion } from 'framer-motion';
import { CreditCard } from 'lucide-react';

const FurtherProcessing = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white text-center p-4">
      <motion.h2
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="text-2xl font-semibold text-gray-800 mb-6 flex items-center"
      >
        <CreditCard className="mr-2" size={24} />
        Further Processing Required
      </motion.h2>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="w-full max-w-md"
      >
        <p className="mb-6 text-gray-600">
          To complete your submission, we need to collect some additional information. 
          You will be redirected to Stripe, our secure payment processor, to provide this information.
        </p>
      </motion.div>
    </div>
  );
};

export default FurtherProcessing;