// src/PiggybackLandingPage.jsx

import React from 'react';
import HeroSection from './components/HeroSection';
import FeaturesSection from './components/FeaturesSection';
import HowItWorksSection from './components/HowItWorksSection';
import TestimonialsSection from './components/TestimonialsSection';
import ImpactDashboard from './components/ImpactDashboard';
import CTASection from './components/CTASection';
import ContactSection from './components/ContactSection';


const PiggybackLandingPage = () => {
  return (
    <div className="min-h-screen bg-white text-black">
      <main className="pt-10">
        <HeroSection />
        <FeaturesSection />
        <HowItWorksSection />
        <TestimonialsSection />
        <ImpactDashboard />
        <CTASection />
        <ContactSection />
      </main>
    </div>
  );
};

export default PiggybackLandingPage;
