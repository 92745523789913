import React from 'react';
import { motion } from 'framer-motion';

const ImpactDashboard = () => {
  return (
    <section id="features" className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <motion.h2 
          className="text-4xl md:text-5xl font-bold text-center mb-12 text-[#ffc0c6]"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Key Features
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <FeatureCard 
            title="Real-time Tracking"
            description="Track your packages in real-time with our advanced GPS technology."
          />
          <FeatureCard 
            title="Secure Payments"
            description="Enjoy safe and secure transactions with our encrypted payment system."
          />
          <FeatureCard 
            title="24/7 Support"
            description="Our dedicated support team is always ready to assist you."
          />
          <FeatureCard 
            title="Eco-friendly Options"
            description="Choose green delivery options to reduce your carbon footprint."
          />
          <FeatureCard 
            title="Flexible Scheduling (coming soon)"
            description="Set your preferred delivery times that fit your schedule."
          />
          <FeatureCard 
            title="Community Ratings"
            description="Make informed choices with our transparent rating system."
          />
        </div>
      </div>
    </section>
  );
};

const FeatureCard = ({ title, description }) => (
  <motion.div 
    className="bg-white p-6 rounded-lg shadow-md border border-[#ffc0c6]"
    whileHover={{ y: -5, boxShadow: '0 4px 6px -1px rgba(255, 192, 198, 0.1), 0 2px 4px -1px rgba(255, 192, 198, 0.06)' }}
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    <h3 className="text-xl font-bold mb-3 text-[#ffc0c6]">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </motion.div>
);

export default ImpactDashboard;
