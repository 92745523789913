import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="bg-gradient-to-b from-[#FFC0C6] to-white min-h-screen pt-24 pb-16">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white shadow-2xl rounded-2xl overflow-hidden">
          <div className="px-8 py-12 sm:px-12 sm:py-16">
            <h1 className="text-5xl font-extrabold text-[#FF8A95] text-center mb-12">Privacy Policy</h1>
            <div className="prose prose-lg max-w-none">
              <p className="text-2xl text-[#FF8A95] mb-10 text-center font-light">
                Our Privacy Policy applies to all services offered or provided by Piggyback, LLC. and its subsidiaries.
              </p>
              
              <h2 className="text-3xl font-semibold text-[#FF8A95] mt-16 mb-6">Assistance For The Disabled</h2>
              <p className="text-gray-700">
                Alternative formats of this Privacy Policy are available to individuals with a disability. Please contact <a href="mailto:support@piggybackit.com" className="text-[#FF8A95] hover:text-[#FF6B78] underline">support@piggybackit.com</a> for assistance.
              </p>
              
              <h2 className="text-3xl font-semibold text-[#FF8A95] mt-16 mb-6">This Privacy Policy explains:</h2>
              <ul className="list-disc pl-8 space-y-2 text-gray-700">
                <li>The Categories of Personal Information We Collect.</li>
                <li>The Categories of Sources From Which We Collect Your Personal Information.</li>
                <li>The Purposes For Which We Use Your Personal Information.</li>
                <li>How We May Disclose Your Personal Information.</li>
                <li>How Long We Keep Your Personal Information.</li>
                <li>Your Rights and Choices Regarding Your Information.</li>
                <li>All Users.</li>
                <li>California Residents.</li>
                <li>Other Things for You to Know.</li>
                <li>Contact Us.</li>
                <li>Changes To Our Privacy Policy.</li>
              </ul>
              
              <h2 className="text-3xl font-semibold text-[#FF8A95] mt-16 mb-6">The Categories of Personal Information We Collect</h2>
              <p className="text-gray-700">
                "Personal information" means information that identifies, relates to, describes, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular individual or household.
              </p>
              
              <h3 className="text-2xl font-semibold text-[#FF8A95] mt-12 mb-4">A. Senders and Recipients</h3>
              <ul className="list-disc pl-8 space-y-2 text-gray-700">
                <li>Identifiers, for example, name, email address, phone number(s), and profile photo.</li>
                <li>Commercial information, for example, records of Delivery Services provided and received.</li>
                <li>Internet activity information, for example, information about your usage of the Platform and our Services.</li>
                <li>Geolocation information, for example, for pick-up and delivery addresses.</li>
                <li>Financial information, for example, payment card and bank account details and billing address.</li>
                <li>Communications data, for example, during our communications with you we collect the content of these communications as well as metadata about the communications.</li>
                <li>Piggyback referral programs, for example, if you participate in any Piggyback referral program.</li>
                <li>Inferences, for example, we may draw inferences about you from other personal information we collect.</li>
              </ul>
              
              {/* ... Continue with the rest of the sections ... */}
              
              <h2 className="text-3xl font-semibold text-[#FF8A95] mt-16 mb-6">Contact Us</h2>
              <p className="text-gray-700">
                If you have questions about anything in this Privacy Policy, please contact us at <a href="mailto:support@piggybackit.com" className="text-[#FF8A95] hover:text-[#FF6B78] underline">support@piggybackit.com</a>.
              </p>
              
              <h2 className="text-3xl font-semibold text-[#FF8A95] mt-16 mb-6">Changes To Our Privacy Policy</h2>
              <p className="text-gray-700">
                Piggyback may update this Privacy Policy from time to time to reflect changes to our practices or in applicable law. Any changes will be effective immediately upon the posting of the revised Privacy Policy. If we make any material changes, we will notify you via email, on our Website and/or in our App. Please periodically review this page for the latest information on our privacy practices.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;