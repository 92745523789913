import React from 'react';

const CookiePolicy = () => {
  return (
    <div className="bg-gradient-to-b from-[#FFC0C6] to-white min-h-screen pt-24 pb-16">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white shadow-2xl rounded-2xl overflow-hidden">
          <div className="px-8 py-12 sm:px-12 sm:py-16">
            <h1 className="text-5xl font-extrabold text-[#FF8A95] text-center mb-12">Cookie Policy</h1>
            <div className="prose prose-lg max-w-none">
              <p className="text-2xl text-[#FF8A95] mb-10 text-center font-light">
                This Cookie Policy outlines how Piggyback, LLC uses cookies and similar technologies when you visit our website at www.piggybackit.com.
              </p>

              <h2 className="text-3xl font-semibold text-[#FF8A95] mt-16 mb-6">What Are Cookies?</h2>
              <p className="text-gray-700">
                Cookies are small text files that websites place on your device to store information about your preferences and actions. They are commonly used to improve your browsing experience by making interactions with websites faster and more personalized.
              </p>
              <p className="text-gray-700 mt-4">
                Some cookies are placed by us, while others are placed by third parties that provide services such as analytics or advertising on our behalf. Cookies help enhance the functionality of our website and enable features that might improve your user experience.
              </p>

              <h2 className="text-3xl font-semibold text-[#FF8A95] mt-16 mb-6">Why Do We Use Cookies?</h2>
              <p className="text-gray-700">
                We use cookies for a variety of reasons, including to ensure the functionality of our Website, to understand how our Website is used, to improve your experience, and to deliver personalized content and relevant advertising. Some cookies are essential for the operation of our Website, while others help us gather insights into how visitors interact with it.
              </p>
              <p className="text-gray-700 mt-4">
                We may use both session cookies, which expire when you close your browser, and persistent cookies, which remain on your device for a set period of time or until you delete them.
              </p>

              <h2 className="text-3xl font-semibold text-[#FF8A95] mt-16 mb-6">Types of Cookies We Use</h2>
              <ul className="list-disc pl-8 space-y-4 text-gray-700">
                <li><strong className="text-[#FF8A95]">Essential Cookies:</strong> These cookies are necessary for the Website to function properly. They allow you to navigate the site and use its features, such as accessing secure areas.</li>
                <li><strong className="text-[#FF8A95]">Performance Cookies:</strong> These cookies collect information about how visitors use the Website, such as which pages are visited most often. This data helps us improve the Website's performance.</li>
                <li><strong className="text-[#FF8A95]">Functional Cookies:</strong> These cookies allow the Website to remember your preferences, such as your language or location, to provide a more personalized experience.</li>
                <li><strong className="text-[#FF8A95]">Targeting Cookies:</strong> These cookies are used to deliver advertisements that are relevant to you and your interests. They also help measure the effectiveness of advertising campaigns.</li>
              </ul>

              <h2 className="text-3xl font-semibold text-[#FF8A95] mt-16 mb-6">Managing Cookies</h2>
              <p className="text-gray-700">
                You have the right to manage and control the use of cookies on our Website. You can adjust your browser settings to refuse or delete cookies, but this may affect your ability to use certain features on our Website. For more detailed information on how to manage cookies, you can refer to your browser's help section.
              </p>

              <h2 className="text-3xl font-semibold text-[#FF8A95] mt-16 mb-6">Changes to This Cookie Policy</h2>
              <p className="text-gray-700">
                We may update this Cookie Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. Please revisit this page regularly to stay informed about how we use cookies.
              </p>

              <h2 className="text-3xl font-semibold text-[#FF8A95] mt-16 mb-6">Contact Us</h2>
              <p className="text-gray-700">
                If you have any questions about our use of cookies or this policy, please contact us at <a href="mailto:support@piggybackit.com" className="text-[#FF8A95] hover:text-[#FF6B78] underline">support@piggybackit.com</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiePolicy;
