// src/components/CTASection.js

import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ArrowRight, Package, Truck, Users, Zap } from 'lucide-react';

const CTASection = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <section className="py-24 relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-br from-ffc0c6 via-ffc0c6 to-pink-400 opacity-90"></div>
      <div className="absolute inset-0 bg-pattern opacity-10"></div>
      
      <motion.div 
        className="container mx-auto px-4 relative z-10"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <div className="max-w-3xl mx-auto text-center">
          <motion.h2 
            className="text-4xl md:text-5xl font-bold mb-6 text-white shadow-text"
            initial={{ scale: 0.9 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            Ready to Revolutionize Your Deliveries?
          </motion.h2>
          <motion.p 
            className="text-xl mb-10 text-white shadow-text"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3, duration: 0.5 }}
          >
            Join Piggybackit today and be part of the community that's reshaping the future of package delivery. Experience the power of shared journeys and sustainable logistics.
          </motion.p>
          
          <motion.div
            className="relative inline-block"
            onHoverStart={() => setIsHovered(true)}
            onHoverEnd={() => setIsHovered(false)}
          >
            <motion.button 
              className="bg-white text-ffc0c6 px-10 py-4 rounded-full text-lg font-semibold hover:bg-opacity-90 transition duration-300 flex items-center mx-auto shadow-lg"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Get Started Now
              <ArrowRight className="ml-2" size={20} />
            </motion.button>
            <AnimatePresence>
              {isHovered && (
                <motion.div 
                  className="absolute -top-20 left-1/2 transform -translate-x-1/2"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 10 }}
                  transition={{ duration: 0.3 }}
                >
                  <div className="bg-white rounded-lg shadow-xl p-4 flex space-x-4">
                    <IconBubble icon={Package} text="Send" />
                    <IconBubble icon={Truck} text="Deliver" />
                    <IconBubble icon={Users} text="Connect" />
                    <IconBubble icon={Zap} text="Earn" />
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        </div>
      </motion.div>
      
      <BouncingPackages />
    </section>
  );
};

const IconBubble = ({ icon: Icon, text }) => (
  <div className="flex flex-col items-center">
    <div className="bg-ffc0c6 p-2 rounded-full mb-1">
      <Icon size={24} className="text-white" />
    </div>
    <span className="text-sm font-medium text-gray-800">{text}</span>
  </div>
);

const BouncingPackages = () => {
  return (
    <div className="absolute inset-0 pointer-events-none">
      {[...Array(5)].map((_, i) => (
        <motion.div
          key={i}
          className="absolute"
          style={{
            top: `${Math.random() * 100}%`,
            left: `${Math.random() * 100}%`,
          }}
          animate={{
            y: [0, -20, 0],
            rotate: [0, 10, -10, 0],
          }}
          transition={{
            duration: 3 + Math.random() * 2,
            repeat: Infinity,
            ease: "easeInOut",
          }}
        >
          <Package size={24 + Math.random() * 24} className="text-white opacity-30" />
        </motion.div>
      ))}
    </div>
  );
};

export default CTASection;
