// src/components/Navbar.js

import React, { useState } from 'react';
import { Link } from 'react-scroll';
import { Menu, X } from 'lucide-react';
import logo from '../Images/pblogo.png';

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <header className="fixed top-0 left-0 right-0 bg-white shadow-md z-50">
            <div className="container mx-auto px-4 py-4 flex justify-between items-center">
                <a href="/" className="flex items-center">
                    <img 
                        src={logo} 
                        alt="Piggyback Logo" 
                        className="h-8 md:h-12 w-auto object-contain" 
                    />
                </a>
                <nav className="hidden md:flex space-x-6">
                    <NavLink to="features" smooth={true} duration={500}>Features</NavLink>
                    <NavLink to="how-it-works" smooth={true} duration={500}>How It Works</NavLink>
                    <NavLink to="testimonials" smooth={true} duration={500}>Testimonials</NavLink>
                    <NavLink to="contact" smooth={true} duration={500}>Contact</NavLink>
                </nav>
                <div className="hidden md:block relative">
                    <button className="bg-ffc0c6 text-black px-6 py-2 rounded-full font-semibold hover:bg-opacity-80 transition duration-300">
                        Download Now
                    </button>
                    <div className="absolute right-0 mt-2 w-48 bg-white shadow-lg rounded-lg hidden group-hover:block">
                        <a href="https://apps.apple.com/us/app/piggyback/id0000000000" className="block px-4 py-2 text-black hover:bg-ffc0c6">iOS</a>
                        <a href="https://play.google.com/store/apps/details?id=com.yourcompany.piggyback" className="block px-4 py-2 text-black hover:bg-ffc0c6">Android</a>
                    </div>
                </div>
                <button 
                    className="md:hidden text-black"
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                >
                    {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
                </button>
            </div>

            {isMenuOpen && (
                <div className="fixed inset-0 bg-white z-40 pt-20">
                    <nav className="flex flex-col items-center space-y-6 py-6">
                        <NavLink to="features" smooth={true} duration={500} onClick={() => setIsMenuOpen(false)}>Features</NavLink>
                        <NavLink to="how-it-works" smooth={true} duration={500} onClick={() => setIsMenuOpen(false)}>How It Works</NavLink>
                        <NavLink to="testimonials" smooth={true} duration={500} onClick={() => setIsMenuOpen(false)}>Testimonials</NavLink>
                        <NavLink to="contact" smooth={true} duration={500} onClick={() => setIsMenuOpen(false)}>Contact</NavLink>
                        <div className="bg-ffc0c6 text-black px-6 py-2 rounded-full font-semibold hover:bg-opacity-80 transition duration-300">
                            <a href="https://apps.apple.com/us/app/piggyback/id0000000000" onClick={() => setIsMenuOpen(false)} className="block">iOS</a>
                            <a href="https://play.google.com/store/apps/details?id=com.yourcompany.piggyback" onClick={() => setIsMenuOpen(false)} className="block mt-2">Android</a>
                        </div>
                    </nav>
                </div>
            )}
        </header>
    );
};

const NavLink = ({ to, children, ...props }) => (
    <Link to={to} className="text-black hover:text-ffc0c6 transition duration-300 cursor-pointer" {...props}>{children}</Link>
);

export default Navbar;
