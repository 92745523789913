// src/components/SubmissionIndicator.js

import React from 'react';
import { motion } from 'framer-motion';
import { CheckCircle } from 'lucide-react';

const SubmissionIndicator = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white text-center p-4">
      <motion.div
        initial={{ scale: 0.5, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ type: 'spring', stiffness: 120, damping: 20 }}
        className="mb-6"
      >
        <CheckCircle className="text-green-500" size={64} />
      </motion.div>
      <motion.h2
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="text-2xl font-semibold text-gray-800 mb-2"
      >
        Information Submitted Successfully!
      </motion.h2>
      <motion.p
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.4 }}
        className="text-gray-600 mb-4"
      >
        Thank you for submitting your information. We will update you promptly with a response regarding your acceptance.
      </motion.p>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.6 }}
      >
        <button
          className="bg-ffc0c6 text-white px-6 py-2 rounded-full font-semibold hover:bg-opacity-90 transition duration-300"
          onClick={() => window.location.href = '/'} // Redirect or perform any other action
        >
          Back to Home
        </button>
      </motion.div>
    </div>
  );
};

export default SubmissionIndicator;
