import React from 'react';

const StoreBadge = ({ iosUrl, androidUrl, height = '60px' }) => {
  return (
    <div className="flex space-x-4">
      <a href={iosUrl} target="_blank" rel="noopener noreferrer">
        <img 
          src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg" 
          alt="Download on the App Store" 
          style={{ height }} 
        />
      </a>
      <a href={androidUrl} target="_blank" rel="noopener noreferrer">
        <img 
          src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" 
          alt="Get it on Google Play" 
          style={{ height }} 
        />
      </a>
    </div>
  );
};

export default StoreBadge;
