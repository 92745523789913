// src/components/HeroSection.js

import React from 'react';
import { motion } from 'framer-motion';
import { Package, Truck, Users, Leaf } from 'lucide-react';
import StoreBadge from './StoreBadge';

const HeroSection = () => {
  return (
    <section className="relative py-24 overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-br from-ffc0c6 via-pink-300 to-purple-400"></div>
      <div className="absolute inset-0 bg-pattern opacity-10"></div>
      
      <div className="container mx-auto px-4 relative z-10">
        <div className="flex flex-col md:flex-row items-center">
          <motion.div 
            className="md:w-1/2 mb-10 md:mb-0"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <h1 className="text-5xl md:text-7xl font-bold mb-6 text-white shadow-text">
              It's On Someone's Way
            </h1>
            <p className="text-xl mb-8 text-white shadow-text">
              Join Piggyback and revolutionize the way you send and receive packages. Eco-friendly, cost-effective, and community-driven.
            </p>
            <div className="flex justify-center">
              {/* <motion.button 
                className="bg-white text-ffc0c6 px-8 py-3 rounded-full text-lg font-semibold hover:bg-opacity-90 transition duration-300 flex items-center justify-center shadow-lg"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Get Started
                <ArrowRight className="ml-2" size={20} />
              </motion.button> */}
            </div>
          </motion.div>
          
          <motion.div 
            className="md:w-1/2 flex flex-col items-center"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <div className="bg-white p-8 rounded-2xl shadow-2xl">
              <h2 className="text-2xl font-bold mb-6 text-ffc0c6">Download our app</h2>
              <StoreBadge 
                iosUrl="https://apps.apple.com/us/app/piggyback/id0000000000"
                androidUrl="https://play.google.com/store/apps/details?id=com.yourcompany.piggyback"
                height="60px"
              />
              <div className="mt-8 grid grid-cols-2 gap-4">
                <FeatureItem icon={Package} text="Easy Shipping" />
                <FeatureItem icon={Truck} text="Fast Delivery" />
                <FeatureItem icon={Users} text="Community Driven" />
                <FeatureItem icon={Leaf} text="Eco-Friendly" />
              </div>
            </div>
          </motion.div>
        </div>
      </div>
      
      <FloatingPackages />
    </section>
  );
};

const FeatureItem = ({ icon: Icon, text }) => (
  <div className="flex items-center">
    <Icon size={24} className="text-ffc0c6 mr-2" />
    <span className="text-sm font-medium">{text}</span>
  </div>
);

const FloatingPackages = () => (
  <div className="absolute inset-0 pointer-events-none">
    {[...Array(8)].map((_, i) => (
      <motion.div
        key={i}
        className="absolute"
        style={{
          top: `${Math.random() * 100}%`,
          left: `${Math.random() * 100}%`,
        }}
        animate={{
          y: [0, -20, 0],
          rotate: [0, 10, -10, 0],
        }}
        transition={{
          duration: 3 + Math.random() * 2,
          repeat: Infinity,
          ease: "easeInOut",
        }}
      >
        <Package size={24 + Math.random() * 24} className="text-white opacity-30" />
      </motion.div>
    ))}
  </div>
);

export default HeroSection;
